<template>
    <section class="relative text-white bg-cover bg-no-repeat" :style="backgroundStyles">
        <UContainer class="grid gap-y-9 py-12 lg:py-36">
            <div class="grid gap-y-8 lg:max-w-3xl">
                <slot name="title">
                    <h1 class="text-5xl font-bold lg:text-7xl before:block before:h-1 before:w-14 before:bg-blue-700 before:rounded before:mb-4">
                        <slot name="title-text">
                            Reliable Car Services You Can Trust
                        </slot>
                    </h1>
                </slot>

                <slot name="subtitle">
                    <h2 class="text-xl">
                        <slot name="subtitle-text">
                            Your trusted auto repair service in Irvine, delivering reliable repairs and personalized care to keep your vehicle running smoothly.
                        </slot>
                    </h2>
                </slot>

                <div class="flex items-start gap-x-2 md:gap-x-3 gap-y-3 md:flex-row justify-between py-2.5 px-2 md:px-7 md:items-center rounded-md backdrop-blur bg-white/10 text-sm md:text-base">
                    <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
                        <UIcon name="i-heroicons-outline-star" class="w-11 h-11 shrink-0 text-slate-300" />
                        <div class="text-slate-200 font-bold">Over 10 Years of Experience</div>
                    </div>
                    <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
                        <UIcon name="i-heroicons-outline-wrench" class="w-11 h-11 shrink-0 text-slate-300" />
                        <div class="text-slate-200 font-bold">Over 1,000 Vehicles Serviced</div>
                    </div>
                    <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
                        <UIcon name="i-heroicons-outline-chat-bubble-oval-left-ellipsis" class="w-11 h-11 shrink-0 text-slate-300" />
                        <div class="text-slate-200 font-bold">More Than 500 Honest Reviews</div>
                    </div>
                </div>
            </div>

            <div>
                <UButton size="xl" class="w-full justify-center md:w-auto">Schedule Repair</UButton>
            </div>
        </UContainer>
    </section>
</template>

<script setup>
const props = defineProps({
    bgImage: {
        type: String,
        default: 'images/car-auto-service.webp'
    }
});

const img = useImage();

const backgroundStyles = computed(() => {
    const imgUrl = img(props.bgImage, {format: 'webp'})
    return {
        backgroundImage: `url('${imgUrl}')`
    };
});
</script>
